var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "Login base-height" }, [
      _c("div", { staticClass: "login_left" }),
      _c("div", { staticClass: "mainBox" }, [
        _c(
          "h1",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/reviewmind/logo2.png"),
                  alt: "로고"
                }
              })
            ])
          ],
          1
        ),
        _c("h2", [_vm._v(_vm._s(_vm.$t("findPwd")))]),
        _c("div", { staticClass: "form login" }, [
          _c("fieldset", [
            _c("legend", [_vm._v("로그인")]),
            _c("div", { staticClass: "cover bg" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                attrs: { type: "text", placeholder: _vm.$t("email") },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "cover bg" }, [
              _c("div", { staticClass: "phone" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mobileNo,
                      expression: "mobileNo"
                    }
                  ],
                  attrs: { type: "text", placeholder: _vm.$t("findId_mobile") },
                  domProps: { value: _vm.mobileNo },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mobileNo = $event.target.value
                    }
                  }
                }),
                _c(
                  "button",
                  {
                    attrs: { id: "phone_btn" },
                    on: { click: _vm.requestCertNumber }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]
                )
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veyfyCodeVisible,
                      expression: "veyfyCodeVisible"
                    }
                  ],
                  staticClass: "cover bg"
                },
                [
                  _c("div", { staticClass: "phone" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.certNumber,
                          expression: "certNumber"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("certNumber")
                      },
                      domProps: { value: _vm.certNumber },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.certNumber = $event.target.value
                        }
                      }
                    }),
                    _c(
                      "button",
                      {
                        attrs: { id: "phone_btn" },
                        on: { click: _vm.verifyNumber }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
                    )
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "cover bg" }, [
              _c(
                "button",
                {
                  staticClass: "btn findEmail primary",
                  attrs: { type: "button" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" " + _vm._s(_vm.$t("findPwd")) + " ")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }